import { Observable } from 'rxjs';
import { BreadcrumbsMenuItem } from './breadcrumb-menu.model';
import { Injectable } from "@angular/core";
import { OverlayPanel } from "primeng/overlaypanel";

export interface BreadcrumbsMenu {
  getBreadcrumbsMenu(...args: unknown[]): Observable<BreadcrumbsMenuItem[]>;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsMenuService {
  private overlayPanel: OverlayPanel | null = null;

  setOverlayPanel(overlayPanel: OverlayPanel) {
    this.overlayPanel = overlayPanel;
  }

  hideOverlayPanel() {
    if (this.overlayPanel) {
      this.overlayPanel.hide();
    }
  }
}
