import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BiTemplateNameDirectiveModule,
  BreadcrumbsMenuComponentModule,
  BreadcrumbsMenuItem
} from "@selfai-platform/bi-shared";
import { TranslateService } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { TooltipModule } from "primeng/tooltip";
import {
  DestroyService,
  PermissionService,
  PipelinePermissionEntity,
  WorkflowPermissionAction
} from "@selfai-platform/shared";
import { DialogService, provideDialogService } from "@selfai-platform/shell";
import { WorkflowClonePayload, WorkflowInfo } from "@selfai-platform/pipeline-common";
import { WorkflowListComponentService } from "../workflow-list-component.service";
import { WorkflowAddDialogComponent } from "../workflow-add-dialog/workflow-add-dialog.component";
import { WorkflowUploadDialogComponent } from "../workflow-upload-dialog/workflow-upload-dialog.component";
import { Observable, takeUntil } from "rxjs";

@Component({
  selector: 'selfai-platform-workflow-list-toolbar',
  standalone: true,
  imports: [
    CommonModule,
    BiTemplateNameDirectiveModule,
    BreadcrumbsMenuComponentModule,
    ButtonModule,
    TooltipModule
  ],
  templateUrl: './workflow-list-toolbar.component.html',
  styleUrls: ['./workflow-list-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideDialogService()]
})
export class WorkflowListToolbarComponent {

  permissionAction = WorkflowPermissionAction;

  breadcrumbItems: BreadcrumbsMenuItem[] = [{
    label: this.translate.instant('shell.dp.ui.flows'),
    routerLink: ['/', 'pipeline',]
  }];

  constructor(
    private readonly destroy$: DestroyService,
    private readonly translate: TranslateService,
    private readonly permissionService: PermissionService,
    private readonly workflowListComponentService: WorkflowListComponentService,
    private readonly dialogServiceForUpload: DialogService<{ success?: boolean }>,
    private readonly dialogService: DialogService<WorkflowClonePayload, WorkflowInfo>,
  ) {
  }


  checkPermission(permissionAction: WorkflowPermissionAction, isOwner?: boolean): Observable<boolean> {
    return this.permissionService.checkPermission({
      entity: PipelinePermissionEntity.Workflows,
      isOwner,
      action: permissionAction,
    });
  }

  addWorkflow(): void {
    this.dialogService
      .showDialog(WorkflowAddDialogComponent, {
        header: 'New workflow',
        width: '50%',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((workflow) => {
        if (workflow) {
          this.workflowListComponentService.addWorkflow(workflow);
        }
      });
  }

  uploadWorkflow(): void {
    this.dialogServiceForUpload
      .showDialog(WorkflowUploadDialogComponent, {
        header: 'Upload workflow',
        width: '50%',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((uploadState) => {
        this.workflowListComponentService.loadWorkflowList();
      });
  }
}
