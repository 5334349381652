import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SessionStatus, WorkflowClonePayload, WorkflowInfo } from '@selfai-platform/pipeline-common';
import {
  DestroyService,
  PipelinePermission,
  PipelinePermissionEntity,
  PermissionService,
  WorkflowPermissionAction,
  PageParams
} from '@selfai-platform/shared';
import { DialogService, provideDialogService } from '@selfai-platform/shell';
import { ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Observable, switchMap, take, takeUntil, tap } from 'rxjs';
import { SessionsManagerService } from '../../services/sessions-manager.service';
import { WorkflowCloneDialogComponent } from './workflow-clone-dialog/workflow-clone-dialog.component';
import { Workflow, WorkflowListComponentService } from './workflow-list-component.service';

@Component({
  selector: 'selfai-platform-workflow-list',
  templateUrl: './workflow-list.component.html',
  styleUrls: ['./workflow-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WorkflowListComponentService, DestroyService, provideDialogService()],
})
export class WorkflowListComponent implements OnInit, OnDestroy {
  workflowList$!: Observable<Workflow[]>;

  sessionStatus = SessionStatus;
  permission = PipelinePermission;
  permissionAction = WorkflowPermissionAction;

  @ViewChild(Table, { read: Table }) tableRef!: Table;

  tableConfig: { key: string, label: any, width: string, filterType?: string }[] = [
    { key: 'name', label: 'Name', width: 'calc(50% - 41rem)', filterType: 'text' },
    { key: 'description', label: 'Description', width: 'calc(50% - 43rem)', filterType: 'text' },
    { key: 'created', label: 'Created', width: '10rem', filterType: 'date' },
    { key: 'updated', label: 'Last Modified', width: '12rem', filterType: 'date' },
    { key: 'ownerName', label: 'Author', width: '9rem', filterType: 'text' },
    { key: 'running', label: 'Running', width: '9rem', filterType: 'boolean' },
    { key: 'actions', label: '', width: '6rem' },
  ];

  loading = true;

  pageParams: PageParams = { pageSize: 10, pageNumber: 1, totalItems: 0 };
  pageSizes = [10, 20, 30];

  constructor(
    private readonly workflowListComponentService: WorkflowListComponentService,
    private readonly dialogService: DialogService<WorkflowClonePayload, WorkflowInfo>,
    private readonly confirmationService: ConfirmationService,
    private readonly destroy$: DestroyService,
    private readonly sessionsManagerService: SessionsManagerService,
    private readonly permissionService: PermissionService,
  ) {}

  ngOnInit(): void {
    this.loading = true;

    this.sessionsManagerService.startPollingSession();
    this.workflowListComponentService.loadWorkflowList();
    this.workflowList$ = this.workflowListComponentService.getWorkflowList().pipe(
      tap(res => {
        this.pageParams = { pageSize: this.pageParams.pageSize, pageNumber: 1, totalItems: res.length };
        this.loading = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.sessionsManagerService.stopPollingSession();
  }

  filter(event: Event): void {
    this.tableRef.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  removeWorkflow(event: Event, id: string): void {
    this.workflowListComponentService
      .getWorkflowById(id)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe((workflow) => {
        this.confirmationService.confirm({
          target: event.target as EventTarget,
          message: `Are you sure to proceed?\n\r The operation will delete workflow "${
            workflow?.name || ''
          }".\n\r Deletion cannot be undone afterwards.`,
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.workflowListComponentService.deleteWorkflow(id);
          },
        });
      });
  }

  cloneWorkflow(id: string): void {
    this.workflowListComponentService
      .getWorkflowById(id)
      .pipe(
        take(1),
        switchMap((workflow) => {
          return this.dialogService.showDialog(WorkflowCloneDialogComponent, {
            data: workflow,
            header: `Clone workflow - ${workflow?.name || ''}`,
            width: '50%',
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((wokflow) => {
        if (wokflow) {
          this.workflowListComponentService.cloneWorkflow(wokflow);
        }
      });
  }

  stopSessionWorkflow(id: string): void {
    this.workflowListComponentService.updateWorkflowState({ id: id, stopInProgress: true });
    this.sessionsManagerService.stopSession(id);
  }

  checkPermission(permissionAction: WorkflowPermissionAction, isOwner?: boolean): Observable<boolean> {
    return this.permissionService.checkPermission({
      entity: PipelinePermissionEntity.Workflows,
      isOwner,
      action: permissionAction,
    });
  }

  onPageChange(event: PageParams): void {
    this.pageParams = { ...event, totalItems: this.pageParams.totalItems };
  }
}
