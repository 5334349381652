import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BiTemplateNameDirective } from './bi-template-name.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [BiTemplateNameDirective],
  exports: [BiTemplateNameDirective],
})
export class BiTemplateNameDirectiveModule {}
