<div class="p-breadcrumb p-component">
  <ul>
    <li class="p-element p-breadcrumb-home">
      <button
        pButton
        type="button"
        icon="pi pi-sitemap"
        class="p-button-text p-button-link p-menuitem-link"
        tabindex="0"
        (click)="toggle($event)"
      ></button>
    </li>

    <li class="p-breadcrumb-chevron pi pi-angle-down" (click)="toggle($event)"></li>

    <ng-container *ngFor="let breadcrumbItem of breadcrumbItems; let i = index">
      <li class="p-element flex align-items-center overflow-hidden">
        <i *ngIf="breadcrumbItem.icon" [ngClass]="['mr-2', breadcrumbItem.icon]"></i>
        <a
          *ngIf="breadcrumbItem.routerLink || !breadcrumbItem.command; else buttonItem"
          class="p-menuitem-link short-text"
          [routerLink]="breadcrumbItem.routerLink"
          [queryParams]="breadcrumbItem.queryParams"
        >
          <span class="p-menuitem-text short-text">{{ breadcrumbItem.label }}</span>
        </a>
        <ng-template #buttonItem>
          <button
            *ngIf="breadcrumbItem.command"
            pButton
            type="button"
            class="p-button-text p-button-link p-menuitem-link"
            tabindex="0"
            (click)="breadcrumbItem.command({ originalEvent: $event, item: breadcrumbItem })"
          >
            <span class="p-menuitem-text">{{ breadcrumbItem.label }}</span>
          </button>
        </ng-template>
        <i
          *ngIf="breadcrumbItem.description"
          class="pi pi-info-circle ml-2"
          pTooltip="{{ breadcrumbItem.description }}"
        ></i>
      </li>

      <ng-container *ngIf="i < breadcrumbItems.length - 1">
        <ng-container *ngIf="breadcrumbItem.items as subMenuItems; else noSubMenu">
          <li class="p-breadcrumb-chevron pi pi-chevron-right" (click)="menu.toggle($event)"></li>
          <p-tieredMenu #menu [model]="subMenuItems" appendTo="body" [popup]="true"></p-tieredMenu>
        </ng-container>
      </ng-container>
    </ng-container>
  </ul>
</div>

<ng-template #noSubMenu>
  <li class="p-breadcrumb-chevron pi pi-chevron-right"></li>
</ng-template>

<p-overlayPanel styleClass="bi-shared-breadcrumbs-menu-overlay-panel" #op>
  <ng-template pTemplate="content">
    <ng-container *ngTemplateOutlet="rootMenuTemplate"></ng-container>
  </ng-template>
</p-overlayPanel>
