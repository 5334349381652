import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AngularHookNames,
  CubeComment,
  CubeWorkflowData,
  GraphNodeOptions,
  GraphNodeOptionSerialized,
  IAngularJsPipelineOptions,
  InferredWorkflowStateError,
  KeRefButtonEditResult,
  KeRefButttonEditorOptions,
  PipelineVariablesEditorOptions,
  PipelineVariablesEditResult,
  VersionManagerActions,
  VersionsManagerRunResult,
  VersionsManagerWorkflowData,
  WorkflowEditorActions,
  WorkflowExpressionData,
  WorkflowJsonToColumnTransformationData,
  WorkflowTriggerData,
} from '@selfai-platform/pipeline-common';
import { AlertOptions, AlertService, AlertSeverity, DestroyService } from '@selfai-platform/shared';
import { ErrorService, provideDialogService } from '@selfai-platform/shell';
import { WorkflowStateService } from '@selfai-platform/storage';
import { ConfirmationService } from 'primeng/api';
import { Observable, Subscription, takeUntil } from 'rxjs';
import { AngularJsBridgeService } from '../angularjs-services/AngularJsBridgeService';
import { AngularJsLoaderService } from '../angularjs-services/AngularJsLoaderService';
import { CubeDialogService } from '../cubes/services/cube-dialog.service';
import { FavoriteCubeService } from '../cubes/services/favorite-cubes/favorite-cube.service';
import { PipelineVariablesService } from '../variables-editor/services/pipeline-variables.service';
import { VersionsManagerService } from '../versions-manager';
import { WorkflowEditorElementService } from './workflow-editor-element.services';
import { WorkflowEditorFacadeservice } from './workflow-editor-facade.service';
import { KeRefButtonsService } from '../ref-buttons-editor';

@Component({
  selector: 'selfai-platform-workflow-editor',
  templateUrl: './workflow-editor.component.html',
  styleUrls: ['./workflow-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DestroyService,
    ConfirmationService,
    ...provideDialogService(
      CubeDialogService,
      FavoriteCubeService,
      VersionsManagerService,
      PipelineVariablesService,
      KeRefButtonsService,
      ErrorService,
    ),
  ],
})
export class WorkflowEditorComponent implements OnInit, OnDestroy {
  workflowId: string | null = null;
  app: ng.auto.IInjectorService | undefined;
  $currentErrorWindow: Subscription | undefined;
  showPipeline = true;
  $versionDialog: Subscription | undefined;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly angularJsLoaderService: AngularJsLoaderService,
    private readonly router: Router,
    private readonly variablesService: PipelineVariablesService,
    private readonly cubeDialogService: CubeDialogService,
    private readonly versionsManagerService: VersionsManagerService,
    private readonly errorService: ErrorService,
    private readonly $destroy: DestroyService,
    private readonly workflowEditorFacadeService: WorkflowEditorFacadeservice,
    private readonly favoriteCubeService: FavoriteCubeService,
    private readonly workflowStateService: WorkflowStateService,
    private readonly elementRef: ElementRef,
    private readonly alertService: AlertService,
    private readonly workflowEditorElementService: WorkflowEditorElementService,
    private readonly keRefButtonsService: KeRefButtonsService,
    private readonly angularBridgeService: AngularJsBridgeService,
  ) {}

  ngOnInit(): void {
    this.initPipeline();
  }

  private initPipeline() {
    this.route.paramMap.subscribe((params) => {
      this.workflowId = params.get('id') as string;
      const options: IAngularJsPipelineOptions = {
        workflowId: this.workflowId,
      };
      this.angularJsLoaderService.bootstrapPipeline(options, () => {
        this.setHooks();
        this.workflowEditorElementService.initService(this.elementRef.nativeElement);
      });
    });
  }

  reloadPAge() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  ngOnDestroy(): void {
    this.angularJsLoaderService.destroyPipeline();
    this.closeError();
    this.workflowStateService.clearWorkflowState();
  }

  closeError() {
    this.$currentErrorWindow?.unsubscribe();
  }

  closeVersionManagerDialog() {
    this.$versionDialog?.unsubscribe();
    this.versionsManagerService.closeDialog();
  }

  onDropCube(
    event: CdkDragDrop<GraphNodeOptionSerialized[], GraphNodeOptionSerialized[], Observable<GraphNodeOptionSerialized>>,
  ): void {
    if (event.item.data) {
      event.item.data.subscribe((data: GraphNodeOptionSerialized) => {
        this.workflowEditorFacadeService.addNode(data, event.dropPoint);
      });
    }
  }

  private setHooks() {
    this.registerNavigateToHook();
    this.registerGoToWorkflowHook();
    this.registerShowErrorWindowHook();
    this.registerShowVariablesHook();
    this.registerCubeCreateTriggerHook();
    this.registerCubeShowJsonToColumnTransformationHook();
    this.registerCubeExpressionToolHook();
    this.registerCubeCommentHook();
    this.registerCubeAddToFavoriteHook();
    this.registerCubeCodeEditorTransformation();
    this.registerCmsFormCreator();
    this.registerVersionManagerHook();
    this.registerAlertMessageHook();
    this.registerButtonManagerHook();
  }

  private registerVersionManagerHook() {
    this.angularBridgeService.registerHook(AngularHookNames.ShowVersions, (data) => {
      this.$versionDialog = this.versionsManagerService
        .showEditor(data as VersionsManagerWorkflowData)
        .pipe(takeUntil(this.$destroy))
        .subscribe({
          next: (dataDialog?: VersionsManagerRunResult) => {
            if (dataDialog?.action === VersionManagerActions.SaveAndOpenWorkflow && dataDialog.workflow) {
              this.workflowEditorFacadeService.saveAndOpenWorkflow(dataDialog.workflow);
            } else {
              this.workflowEditorFacadeService.reOpenWorkflow();
            }
          },
        });
    });
  }

  private registerButtonManagerHook() {
    this.angularBridgeService.registerHook(AngularHookNames.ShowButtonEditor, (data) => {
      this.keRefButtonsService
        .showEditor(data as KeRefButttonEditorOptions)
        .pipe(takeUntil(this.$destroy))
        .subscribe({
          next: (dataDialog?: KeRefButtonEditResult) => {
            if (dataDialog?.needSave) {
              this.workflowEditorFacadeService.saveRefrenceButtons(dataDialog.items);
            }
          },
        });
    });
  }

  private registerCubeCommentHook() {
    this.angularBridgeService.registerHook(AngularHookNames.CubeComment, (data) => {
      this.cubeDialogService
        .showComment(data as CubeComment)
        .pipe(takeUntil(this.$destroy))
        .subscribe();
    });
  }

  private registerCubeAddToFavoriteHook() {
    this.angularBridgeService.registerHook(AngularHookNames.AddToFavorite, (data) => {
      const nodeOptions = data as GraphNodeOptions;
      this.favoriteCubeService.showFavoriteAddForm(nodeOptions);
    });
  }

  private registerCubeShowJsonToColumnTransformationHook() {
    this.angularBridgeService.registerHook(AngularHookNames.CubeShowJsonToColumnTransformation, (data) => {
      this.cubeDialogService
        .showJsonToColumnTransformation(data as CubeWorkflowData<WorkflowJsonToColumnTransformationData>)
        .pipe(takeUntil(this.$destroy))
        .subscribe();
    });
  }

  private registerCubeExpressionToolHook() {
    this.angularBridgeService.registerHook(AngularHookNames.CubeExpressionTool, (data) => {
      this.cubeDialogService
        .showExpressionTool(data as CubeWorkflowData<WorkflowExpressionData>)
        .pipe(takeUntil(this.$destroy))
        .subscribe();
    });
  }

  private registerCubeCreateTriggerHook() {
    this.angularBridgeService.registerHook(AngularHookNames.CubeCreateTrigger, (data) => {
      this.cubeDialogService
        .showCreateTrigger(data as CubeWorkflowData<WorkflowTriggerData>)
        .pipe(takeUntil(this.$destroy))
        .subscribe();
    });
  }

  private registerCubeCodeEditorTransformation() {
    this.angularBridgeService.registerHook(AngularHookNames.CubeCodeEditorTransformation, (data) => {
      this.cubeDialogService
        .showCodeEditorTransformation(data as CubeWorkflowData)
        .pipe(takeUntil(this.$destroy))
        .subscribe();
    });
  }

  private registerCmsFormCreator() {
    this.angularBridgeService.registerHook(AngularHookNames.CubeCmsFormCreator, (data) => {
      this.cubeDialogService
        .showCmsFormCreator(data as CubeWorkflowData)
        .pipe(takeUntil(this.$destroy))
        .subscribe();
    });
  }

  private registerShowVariablesHook() {
    this.angularBridgeService.registerHook(AngularHookNames.ShowVariables, (data) => {
      this.variablesService
        .showEditor(data as PipelineVariablesEditorOptions)
        .pipe(takeUntil(this.$destroy))
        .subscribe({
          next: (dataDialog?: PipelineVariablesEditResult) => {
            if (dataDialog?.needSave) {
              this.workflowEditorFacadeService.saveWorkflowVariables(dataDialog.items);
            }
          },
        });
    });
  }

  private registerAlertMessageHook() {
    this.angularBridgeService.registerHook(AngularHookNames.AlertMessage, (data) => {
      const { message, severity, options } = data as {
        message: string;
        severity: AlertSeverity;
        options?: AlertOptions;
      };

      this.alertService.message(message, severity, options);
    });
  }

  private registerShowErrorWindowHook() {
    this.angularBridgeService.registerHook(AngularHookNames.ShowErrorWindow, (error) => {
      const err = error as InferredWorkflowStateError;
      this.$currentErrorWindow = this.errorService
        .showError(`${err.title}`, err)
        .pipe(takeUntil(this.$destroy))
        .subscribe({
          complete: () => {
            this.reloadPAge();
          },
        });
    });
  }

  private registerNavigateToHook() {
    this.angularBridgeService.registerHook(AngularHookNames.NavigateTo, (data) => {
      const { url, data: errorData } = data as { url: string; data: Record<string, unknown> };
      this.router.navigateByUrl(url as string, { state: errorData });
    });
  }

  private registerGoToWorkflowHook() {
    this.angularBridgeService.registerHook(AngularHookNames.GoToWorkflow, (workflowId) => {
      // Dirty hack for correct workflow navigate
      this.closeVersionManagerDialog();
      this.router.navigate(['/pipeline'], { skipLocationChange: true }).then(() => {
        this.router.navigate(['/pipeline', 'workflow', workflowId as string]);
      });
    });
  }
}
