import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileUploadModule } from 'primeng/fileupload';
import { WorkflowUploadDialogComponent } from './workflow-upload-dialog.component';
import { CheckboxModule } from "primeng/checkbox";
import { FormsModule } from "@angular/forms";
import { SpoilerComponent } from "@selfai-platform/shared";

@NgModule({
  imports: [CommonModule, FileUploadModule, CheckboxModule, FormsModule, SpoilerComponent],
  declarations: [WorkflowUploadDialogComponent],
  exports: [WorkflowUploadDialogComponent],
})
export class WorkflowUploadDialogComponentModule {}
