import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { TablePaginationComponent } from './table-pagination.component';

@NgModule({
  imports: [CommonModule, PaginatorModule, TranslateModule, ButtonModule],
  declarations: [TablePaginationComponent],
  exports: [TablePaginationComponent],
})
export class TablePaginationComponentModule {}
