import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  QueryList,
  TemplateRef, ViewChild,
} from '@angular/core';
import { BiTemplateNameDirective } from '../bi-template-name';
import { BreadcrumbsMenuItem } from './breadcrumb-menu.model';
import { BreadcrumbsMenuService } from "./breadcrumbs-menu.service";
import { OverlayPanel } from "primeng/overlaypanel";

@Component({
  selector: 'bi-shared-breadcrumbs-menu',
  templateUrl: './breadcrumbs-menu.component.html',
  styleUrls: ['./breadcrumbs-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsMenuComponent implements AfterContentInit {
  @ViewChild('op') overlayPanel!: OverlayPanel;

  @Input() breadcrumbItems!: BreadcrumbsMenuItem[];

  @ContentChildren(BiTemplateNameDirective) templates!: QueryList<BiTemplateNameDirective>;

  rootMenuTemplate!: TemplateRef<unknown>;

  constructor(
    private cdr: ChangeDetectorRef,
    private breadcrumbsMenuService: BreadcrumbsMenuService
  ) {}

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.name) {
        case 'root-menu':
        default:
          this.rootMenuTemplate = item.template;
          break;
      }

      this.cdr.markForCheck();
    });
  }

  toggle(e: MouseEvent) {
    this.breadcrumbsMenuService.setOverlayPanel(this.overlayPanel);
    this.overlayPanel.toggle(e);
  }
}
