import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SemanticCode } from '../../routing';
import { WorkflowPermissionAction } from '../permission-action.enum';
import { PipelinePermissionEntity } from '../pipeline-permission.enum';
import { PermissionService } from '../permission.service';

@Injectable({
  providedIn: 'root',
})
export class CheckPermissionGuard  {
  constructor(private readonly permissionService: PermissionService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const semanticCode: SemanticCode | undefined = route.data?.['semanticCode'];

    if (semanticCode) {
      switch (semanticCode) {
        case SemanticCode.WorkflowList:
          return this.permissionService.checkPermission({
            entity: PipelinePermissionEntity.Workflows,
            action: WorkflowPermissionAction.List,
          });

        default:
          return true;
      }
    }

    return true;
  }
}
