import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TooltipModule } from 'primeng/tooltip';
import { BreadcrumbsMenuComponent } from './breadcrumbs-menu.component';

@NgModule({
  imports: [CommonModule, TieredMenuModule, OverlayPanelModule, ButtonModule, TooltipModule],
  declarations: [BreadcrumbsMenuComponent],
  exports: [BreadcrumbsMenuComponent],
})
export class BreadcrumbsMenuComponentModule {}
