import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[biSharedTemplateName]',
})
export class BiTemplateNameDirective {
  @Input() biSharedTemplateName!: string;

  get name(): string {
    return this.biSharedTemplateName;
  }

  constructor(public readonly template: TemplateRef<unknown>) {}
}
