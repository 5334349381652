<div class="toolbar">
  <bi-shared-breadcrumbs-menu
    *ngIf="breadcrumbItems"
    [breadcrumbItems]="breadcrumbItems"
  ></bi-shared-breadcrumbs-menu>

  <div>
    <button
      *ngIf="checkPermission(permissionAction.Create) | async"
      type="button"
      pButton
      class="p-button-primary"
      appearance="icon"
      icon="pi pi-plus-circle"
      tooltipPosition="top"
      pTooltip="add"
      (click)="addWorkflow()"
    ></button>

    <button
      *ngIf="checkPermission(permissionAction.Upload) | async"
      type="button"
      pButton
      class="p-button-primary ml-2"
      appearance="icon"
      icon="pi pi-upload"
      tooltipPosition="top"
      pTooltip="Upload"
      (click)="uploadWorkflow()"
    ></button>
  </div>
</div>
