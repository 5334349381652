import { Injectable } from '@angular/core';
import {
  PermissionService,
  PipelinePermissionEntity,
  UserProfileService,
  WorkflowPermissionAction,
} from '@selfai-platform/shared';
import {
  Observable,
  combineLatest,
  combineLatestWith,
  filter,
  forkJoin,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs';
import { WorkflowDetailService } from './workflow-detail.service';

@Injectable({
  providedIn: 'root',
})
export class WorkflowPermissionService {
  get workflowPermissionsCore(): WorkflowPermissionAction[] {
    return Object.values(WorkflowPermissionAction);
  }

  constructor(
    private readonly workflowDetailService: WorkflowDetailService,
    private readonly permissionService: PermissionService,
    private readonly userProfileService: UserProfileService,
  ) {}

  getWorkflowPermissions(workflowId: string): Observable<WorkflowPermissionAction[]> {
    return this.permissionService.pipelinePermissionsLoadedObs$.pipe(
      filter((loaded) => loaded),
      combineLatestWith(this.workflowDetailService.loadWorkflow(workflowId), this.userProfileService.getUserProfile()),
      switchMap(([loaded, workflow, userProfile]) => {
        const isOwner = workflow.workflowInfo.ownerId === userProfile.id;

        const permissionChecks$ = this.workflowPermissionsCore.map((permissionAction) =>
          this.permissionService
            .checkPermission({
              entity: PipelinePermissionEntity.Workflows,
              action: permissionAction,
              isOwner,
            })
            .pipe(
              map((hasPermission) => (hasPermission ? permissionAction : null)),
              take(1),
            ),
        );

        return forkJoin(permissionChecks$).pipe(map((results) => results.filter((result) => result !== null)));
      }),
    );
  }
}
