import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Injector, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  ConfigService,
  DestroyService,
  KeBuildVersionService,
  PipelineConfigService,
  ScriptService,
  SharedModule,
} from '@selfai-platform/shared';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AggregatorCamelModule } from './aggregator-camel/aggregator-camel.module';
import {
  CodeEditorTransformationComponentModule,
  CreateTriggerComponentModule,
  ExpressionToolComponentModule,
  JsonToColumnTransformationComponentModule,
} from './cubes/components';
import { CubeListSidebarComponentModule } from './cubes/components/cube-list-sidebar/cube-list-sidebar.module';
import { CmsFormCreatorComponentModule } from './cubes/components/dialogs/cms-form-creator/cms-form-creator.module';
import { FavoriteCubeAddComponentModule } from './cubes/components/favorite-cube-add/favorite-cube-add.module';
import { PipelineVariablesEditorModule } from './variables-editor/pipeline-variables-editor-component.module';
import { PipelineVersionManagerModule } from './versions-manager';
import { WorkflowEditorElementService } from './workflow-editor/workflow-editor-element.services';
import { WorkflowEditorFacadeservice } from './workflow-editor/workflow-editor-facade.service';
import { WorkflowEditorComponent } from './workflow-editor/workflow-editor.component';
import { pipelineModuleRoutes } from './routes';
import { PipelineRbacService } from './rbac';
import { KeBackendInfoService } from './backend-info/ke-backend-info.service';
import { KeRefButtonEditorModule } from './ref-buttons-editor';

@NgModule({
  imports: [
    AggregatorCamelModule,
    CommonModule,
    RouterModule.forChild(pipelineModuleRoutes),
    SharedModule,
    HttpClientModule,
    PipelineVariablesEditorModule,
    CreateTriggerComponentModule,
    JsonToColumnTransformationComponentModule,
    ConfirmDialogModule,
    PipelineVersionManagerModule,
    ExpressionToolComponentModule,
    CubeListSidebarComponentModule,
    DragDropModule,
    FavoriteCubeAddComponentModule,
    CodeEditorTransformationComponentModule,
    CmsFormCreatorComponentModule,
    KeRefButtonEditorModule,
  ],
  declarations: [WorkflowEditorComponent],
  providers: [
    ScriptService,
    DestroyService,
    PipelineConfigService,
    WorkflowEditorElementService,
    WorkflowEditorFacadeservice,
    PipelineRbacService,
  ],
  exports: [ExpressionToolComponentModule, CreateTriggerComponentModule, JsonToColumnTransformationComponentModule],
  schemas: [NO_ERRORS_SCHEMA],
})
export class PipelineModuleModule {
  constructor(
    readonly pipelineRbacService: PipelineRbacService,
    readonly configService: ConfigService,
    readonly injector: Injector,
  ) {
    configService.configLoaded$.subscribe((loaded) => {
      if (!loaded) return;
      pipelineRbacService.loadRbacInfo().subscribe();

      const versionSvc = injector.get(KeBuildVersionService);
      const kdBackendService = injector.get(KeBackendInfoService);
      kdBackendService.getVersion().subscribe((info) => {
        versionSvc.next(info);
      });
    });
  }
}
