<selfai-platform-workflow-list-toolbar></selfai-platform-workflow-list-toolbar>

<p-confirmPopup></p-confirmPopup>
<p-table
  [value]="workflowList$ | async"
  [globalFilterFields]="['name', 'description']"
  sortField="updated"
  [sortOrder]="-1"
  [showLoader]="false"
  [loading]="loading"
  [first]="pageParams.pageSize * (pageParams.pageNumber - 1)"
  [paginator]="true"
  [rows]="pageParams.pageSize"
  styleClass="p-datatable-sm"
  paginatorStyleClass="prime-table-custom__paginator"
>
  <ng-template pTemplate="loadingbody">
    <tr *ngFor="let item of [].constructor(10)" tabindex="-1">
      <td [style.height.px]="35" *ngFor="let _ of [].constructor(7)">
        <p-skeleton class="w-full"></p-skeleton>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td [colSpan]="7">{{ 'shell.board.filter.ui.search-all.nodata' | translate }}</td>
    </tr>
  </ng-template>

  <ng-template pTemplate="caption">
    <div class="flex justify-content-between">
      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>

        <input
          style="width: 300px"
          pAutoFocus
          [autofocus]="true"
          pInputText
          type="text"
          (input)="filter($event)"
          placeholder="{{ 'shell.workbook-list.search.input-placeholder' | translate }}"
        />
      </span>

      <p-dropdown
        [options]="pageSizes"
        [ngModel]="pageParams.pageSize"
        (onChange)="onPageChange({ pageSize: $event.value, pageNumber: 1, totalItems: pageParams.totalItems })"
      ></p-dropdown>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th
        *ngFor="let item of tableConfig"
        [pSortableColumn]="item.key"
        [pSortableColumnDisabled]="!item.filterType"
        [style.width]="item.width">
        <div class="flex align-items-center">
          {{ item.label }}

          <ng-container *ngIf="item.filterType">
            <p-sortIcon [field]="item.key"></p-sortIcon>
            <p-columnFilter [type]="item.filterType" [field]="item.key" display="menu"></p-columnFilter>
          </ng-container>
        </div>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-workflow>
    <tr>
      <td class="cursor-pointer short-text">
        <a [routerLink]="['workflow', workflow.id]">{{ workflow.name }}</a>
      </td>

      <td tabindex="-1" class="cursor-pointer short-text" [routerLink]="['workflow', workflow.id]">
        {{ workflow.description }}
      </td>

      <td tabindex="-1" class="cursor-pointer short-text" [routerLink]="['workflow', workflow.id]">
        {{ workflow.created | date: 'dd.MM.yyyy, hh:mm' }}
      </td>

      <td tabindex="-1" class="cursor-pointer short-text" [routerLink]="['workflow', workflow.id]">
        {{ workflow.updated | date: 'dd.MM.yyyy, hh:mm' }}
      </td>

      <td tabindex="-1" class="short-text">{{ workflow.ownerName }}</td>

      <td tabindex="-1">
        <button
          *ngIf="
            workflow.running &&
            !workflow.stopInProgress &&
            ((checkPermission(permissionAction.Run, workflow.isOwner) | async) ||
              (checkPermission(permissionAction.Execute, workflow.isOwner) | async))
          "
          type="button"
          pButton
          class="p-button-text p-button-link ml-2 p-0"
          appearance="icon"
          (click)="stopSessionWorkflow(workflow.id)"
          icon="pi pi-power-off"
          tooltipPosition="left"
          pTooltip="Stop session"
        ></button>
        <button
          *ngIf="
            workflow.running &&
            workflow.stopInProgress &&
            ((checkPermission(permissionAction.Run, workflow.isOwner) | async) ||
              (checkPermission(permissionAction.Execute, workflow.isOwner) | async))
          "
          type="button"
          pButton
          class="p-button-text p-button-link ml-2 p-0"
          appearance="icon"
          icon="pi pi-spin pi-spinner"
          tooltipPosition="left"
          pTooltip="Stop in progress"
        ></button>
      </td>

      <td class="py-0">
        <div class="flex actions">
          <button
            *ngIf="checkPermission(permissionAction.Clone, workflow.isOwner) | async"
            type="button"
            pButton
            class="p-button-text p-button-link"
            appearance="icon"
            (click)="cloneWorkflow(workflow.id)"
            icon="pi pi-clone"
            tooltipPosition="top"
            pTooltip="Clone workflow"
          ></button>
          <button
            *ngIf="checkPermission(permissionAction.Delete, workflow.isOwner) | async"
            type="button"
            pButton
            class="p-button-text p-button-link"
            appearance="icon"
            (click)="removeWorkflow($event, workflow.id)"
            icon="pi pi-trash"
            tooltipPosition="top"
            pTooltip="Delete workflow"
          ></button>
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer">
    <tr>
      <td class="p-0" [colSpan]="7">
        <bi-shared-table-pagination
          *ngIf="pageParams.totalItems"
          [pageNumber]="pageParams.pageNumber"
          [pageSize]="pageParams.pageSize"
          [totalRecords]="pageParams.totalItems"
          (pageChange)="onPageChange($event)"
        ></bi-shared-table-pagination>
      </td>
    </tr>
  </ng-template>
</p-table>
