import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CreateTriggerData,
  CreateTriggerFormGroup,
  CubeEditResult,
  CubeWorkflowData,
  GraphNodeUpdateParameterValuesOptions,
  TriggerRule,
  TriggerRuleFormGroup,
  WorkflowTriggerData,
} from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { DialogService } from '@selfai-platform/shell';
import { WorkflowEditorFacadeservice } from 'libs/pipeline-module/src/lib/workflow-editor/workflow-editor-facade.service';
import { BehaviorSubject, Observable, filter, map, takeUntil } from 'rxjs';
import {
  extractSelectionItems,
  normalizeData,
  normalizeToLegacyData,
} from '../../../converters/create-trigger.normalizer';
import { SelectionStoreService } from '../../../services/selection-store.service';

interface StoreData {
  nodeId: string;
  data: CreateTriggerData;
}

@Injectable()
export class CreateTriggerComponentService extends BehaviorSubject<StoreData> {
  constructor(
    private readonly destroy$: DestroyService,
    private readonly workflowEditorFacadeService: WorkflowEditorFacadeservice,
    dialogService: DialogService<CubeEditResult, CubeWorkflowData<WorkflowTriggerData>>,
    selectionStoreService: SelectionStoreService,
  ) {
    let data: StoreData = { data: {} } as StoreData;
    if (dialogService.data) {
      const {
        selectedNode: { id: nodeId, parameters },
      } = dialogService.data;
      if (parameters) {
        data = { nodeId, data: normalizeData(parameters.serialize()) };

        const selectionColumns = extractSelectionItems(parameters);
        if (selectionColumns) {
          selectionStoreService.setSelectionsColumns(selectionColumns);
        }
      }
    }

    super(data);
  }

  // rest fields to except conditions
  getFormGroup(): FormGroup<CreateTriggerFormGroup> {
    const { data } = this.value;
    return new FormGroup({
      resultColumn: new FormControl(data.resultColumn, [Validators.required]),
      debugMode: new FormControl(data.debugMode),
    });
  }

  getTriggerRulesFormGroups(): Observable<FormGroup<TriggerRuleFormGroup>[]> {
    return this.asObservable().pipe(
      map(({ data: { conditions } }) => conditions),
      filter(Boolean),
      map((conditions) => conditions.map(this.mapItemToFormGroup)),
    );
  }

  getNodeId(): Observable<string> {
    return this.asObservable().pipe(map(({ nodeId }) => nodeId));
  }

  saveDataToWorkflow(dataForSaving?: CreateTriggerData): void {
    this.pipe(takeUntil(this.destroy$)).subscribe((dataFromState) => {
      const data = dataForSaving || dataFromState.data;
      const graphNodeUpdateOptions: GraphNodeUpdateParameterValuesOptions = {
        id: dataFromState.nodeId,
        parameters: normalizeToLegacyData(data),
      };
      this.workflowEditorFacadeService.updateNodeParamterValues(graphNodeUpdateOptions);
    });
  }

  mapItemToFormGroup(item: TriggerRule): FormGroup<TriggerRuleFormGroup> {
    return new FormGroup<TriggerRuleFormGroup>({
      id: new FormControl(item.id) as FormControl<string>,
      attrColumnName: new FormControl(item.attrColumnName),
      condition: new FormControl(item.condition, [Validators.required]),
      primary: new FormControl(item.primary),
      resultColumn: new FormControl(item.resultColumn),
      value: new FormControl(item.value),
      ignoreCase: new FormControl(item.ignoreCase || false),
      startPosition: new FormControl(item.startPosition || null),
      startRange: new FormControl(item.startRange || null),
      includeStartRange: new FormControl(item.includeStartRange || null),
      endRange: new FormControl(item.endRange || null),
      includeEndRange: new FormControl(item.includeEndRange || null),
    });
  }
}
